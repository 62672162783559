import BaseService from "./base.service";

class CommunityService {
    community(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('community', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('community/'+id);
    }
    add(community) {
        return BaseService.apiPostRequest('community/add', community);
    }
    update(id, community) {
        return BaseService.apiPostRequest('community/'+id, community);
    }

    search(page=null) {
        return BaseService.apiGetRequest('community/search', page);
    }
    getcommunitylist() {
        return BaseService.apiGetRequest('communitylist');
    }
}

export default new CommunityService();